<template>
  <div class="side-box">

    <div class="side-btn" @click="handleCollapse" v-if="isCollapse" @click.stop>
      <img :src="iconCollapse" />
      <P class="side-btn-text">Toolbox</P>
    </div>
    <!-- 菜单栏 -->
    <transition name="opa-fade">
      <div class="menu-list-container" v-if="!isCollapse" @click.stop>
        <div class="close-icon-box" @click="handleCollapse">
          <img :src="iconClose" />
        </div>
        <div class="route-list-container">
          <p v-for="item in routerList" :key="item.name" class="route-item"
            :class="{ 'active-path-style': currentPath === item.path }" @click="handleToPage(item)"
            @mouseleave="handleMouseLeave(item)">
            {{ item.title }}
          </p>
        </div>
        <div class="image-show-tips" v-if="showTips">
          <div class="image-show-tips-arrow"></div>
          <div class="image-show-tips-box">New feature, coming soon</div>
        </div>
        <div class="sider-policy-container">
          <div class="sider-policy" v-for="item in policyRouterList" :key="item.name" @click="handleToPage(item)">
            {{ item.title }}</div>
        </div>

      </div>
    </transition>
  </div>
</template>
<script setup>
import iconCollapse from "@/assets/icon-collapse.svg";
import iconClose from "@/assets/icon_close.svg";
import { useRouter } from "vue-router";
import { ref, watchEffect, defineExpose, onMounted, computed } from "vue";
import { reportData } from '@/utils/buriedFunction/reportData.js'
import store from '@/store';
const router = useRouter();

const currentPath = ref(router.currentRoute.value.fullPath.split("?")[0]);
const showTips = ref(false)

watchEffect(() => {
  console.log('outer.currentRoute.value.fullPath', router.currentRoute.value.fullPath)
  currentPath.value = router.currentRoute.value.fullPath.split("?")[0];
});

const routerList = [
  {
    path: "/filter",
    pathName: "filter",
    title: "Photo Effects & Filters",
  },
  {
    path: "/animation",
    pathName: "dynamicEffects",
    title: "Photo Animation",
  },
  {
    path: "/background-remover",
    pathName: "backGroundRemover",
    title: "Background Remove & Change",
  },
  // {
  //   path: "/removeClothBackground",
  //   pathName: "removeClothBackground",
  //   title: "Remove Cloth Background",
  // },
  {
    path: "/image-resizer",
    pathName: "imageResizer",
    title: "Image Resizer",
  },
  {
    path: "/image-compressor",
    pathName: "imageCompressor",
    title: "Image Compressor",
  },
  {
    path: "/highResolutionPicture",
    pathName: "highResolutionPicture",
    title: "Image Upscaler",
  },

];
const policyRouterList = [
  {
    path: "/terms",
    pathName: "termsOfService",
    title: "Terms of Service",
  },
  {
    path: "/privacy",
    pathName: "privacyPolicy",
    title: "Privacy Policy",
  },
  {
    path: "/cookies",
    pathName: "cookiesPolicy",
    title: "Cookies Policy",
  },
]

const isCollapse = computed(() => {
  return store.getters.getIsCollapse
})

const closeModal = () => {
  store.dispatch('closeSide', { isCollapse: true })
}

defineExpose({
  closeModal,
})


const handleCollapse = () => {
  store.dispatch('closeSide', {
    isCollapse: !isCollapse.value
  })
};


const handleMouseLeave = (item) => {
  if (item.path == "/highResolutionPicture") {
    showTips.value = false
  }
}

const handleToPage = (item) => {
  //对于高清化特殊处理
  const queryString = window.location.search?.split("?") && window.location.search?.split("?")[1]
  const queryList = queryString?.split('&')
  const queryObj = {}
  if (queryList && queryList.length) {
    queryList.forEach(item => {
      const itemList = item.split('=')
      queryObj[itemList[0]] = itemList[1]
    })
  }

  if (item.path === "/highResolutionPicture") {
    window.open("https://booltool.boolv.tech/image-enhancer")
    reportData("toolkits_upscaler_hover")
    return
  }

  if (queryList && queryList.length) {
    router.push({
      name: item.pathName,
      query: queryObj
    })
  } else {
    router.push({
      name: item.pathName,
    })
  }

};


</script>
<style lang="less" scoped>
.side-btn {
  position: absolute;
  top: 48px;
  left: 42px;
  // margin-top: 42px;
  // margin-left: 42px;
  cursor: pointer;
  height: 48px;
  width: 136px;
  background: #000000;
  border-radius: 132px;
}

.side-box {
  color: #ffffff;
  position: relative;
  height: 100%;
  font-family: "Helvetica Neue";
}

.side-btn {
  display: flex;
  align-items: center;
  padding: 0 18px;
  justify-content: space-between;
  box-shadow: 0px 0px 10px #b4b2b2;

  &:hover {
    background: #3c3c3c;
  }
}

.side-btn-text {
  font-size: 18px;
  height: 100%;
  margin-bottom: 0;
  line-height: 48px;
}

.menu-list-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 309px;
  height: 100%;
  background: #ffffff;
  opacity: 0.8;
  box-shadow: 0px 0px 62px rgba(0, 0, 0, 0.07);
  padding: 54px 0 0 42px;
  animation: men-list-animation 0.4s 1;
}

@keyframes men-list-animation {
  0% {
    left: -309px;
  }

  95% {
    left: 15px;
  }

  100% {
    left: 0px;
  }
}

.close-icon-box {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin: 0 0px 43.25px 0;
  border-radius: 4px;

  &:hover {
    background: #ededed;
  }
}

.route-list-container {
  font-size: 16px;
  line-height: 20px;
  font-family: "Helvetica Neue";
  font-style: normal;
  font-weight: 500;
  color: #000000;
  text-align: left;
}



.route-item {
  cursor: pointer;
  width: 100%;
}

.active-path-style {
  color: #875eff;
  width: 100%;
}

.opa-fade-enter-active,
.opa-fade-leave-active {
  transition: all 0.1s ease;
}

.opa-fade-enter-from,
.opa-fade-leave-to {
  left: -309px;
}

.sider-policy-container {
  font-family: "Helvetica Neue";
  color: #8F959E;
  font-size: 14px;
  line-height: 20px;
  text-decoration: underline;
  text-align: left;
  position: absolute;
  bottom: 42px;
}

.sider-policy {
  cursor: pointer;
  margin-top: 16px;
  width: 110px;
}

.image-show-tips {
  position: relative;
  left: -18px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.image-show-tips-arrow {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom: 6px solid #000000;
}

.image-show-tips-box {
  color: #FFFFFF;
  height: 36px;
  background: #000000;
  padding: 8px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 20px;
}

.sider-policy-container {
  width: 110px;
}
</style>