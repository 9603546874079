<template>
  <div class="layout">
    <a-layout>
      <transition name="fade">
        <div class="side-btn-position">
          <Side ref="sideDom" />
        </div>
      </transition>
      <a-layout-content>
        <router-view v-slot="{ Component }">
          <transition name="fade-transform" mode="out-in" appear>
            <component :is="Component" />
          </transition>
        </router-view>
      </a-layout-content>
    </a-layout>
  </div>
</template>
<script setup>
import Side from "./sider/index.vue";
import { onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { useRouter } from "vue-router";
const router = useRouter();
const sideDom = ref(null)

// watchEffect(() => {
//   console.log(' router.currentRoute.value.fullPath', router.currentRoute.value.fullPath)
//   const currentPath = router.currentRoute.value.fullPath
//   if (currentPath.includes('#')) {

//   }
//   // currentPath.value = router.currentRoute.value.fullPath.split("?")[0];
// });
onMounted(() => {
  window.addEventListener('click', sideDom.value.closeModal)
})

onUnmounted(() => {
  window.removeEventListener('click', sideDom.value.closeModal)
})
</script>
<style lang="less">
.layout {
  height: 100%;
  background-color: #fff;
  min-width: 1400px;

  .ant-layout {
    height: 100%;
    background-color: #fff;
  }

  .ant-layout-content {
    background-color: #fff;
    // overflow: scroll;
  }

  .side-btn-position {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    z-index: 999;
  }

  .fade-enter-from,
  .fade-leave-to {
    /*定义进入开始和离开结束的透明度为0*/
    opacity: 0;
  }

  .fade-enter-to,
  .fade-leave-from {
    /*定义进入结束和离开开始的透明度为1*/
    opacity: 1;
  }

  .fade-enter-active,
  .fade-leave-active {
    /*定义进入和离开过渡状态的透明度变化的时间和变化曲线*/
    transition: opacity 0.5s ease;
  }

  img {
    -webkit-user-drag: none;
  }
}
</style>